// Nodes modules
import './modules/@tc';
import 'classlist-polyfill';
import 'object-fit-polyfill';

import Swiper, {SwiperOptions, Pagination, Navigation, Keyboard} from "swiper"

Swiper.use([Pagination, Navigation, Keyboard]);

//import {TweenMax, gsap, ScrollToPlugin, ScrollTrigger, TimelineMax} from "gsap/all";
//gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);


let mm = gsap.matchMedia(); /* Responsive GSAP test function */

/*
let viewportWidthEl = document.getElementById('viewport-width');
let viewportHeightEl = document.getElementById('viewport-height');

function viewportCalc() {
    let viewportHeight = window.innerHeight;
    let viewportWidth = window.innerWidth;
    //console.log(viewportWidth);
    //console.log(viewportHeight);

    viewportWidthEl.innerText = viewportWidth;
    viewportHeightEl.innerText = innerHeight;

    if(window.innerWidth < 768){
        viewportWidthEl.style.backgroundColor = "red";
        viewportHeightEl.style.backgroundColor = "red";

    } else if(window.innerWidth < 992) {
        viewportWidthEl.style.backgroundColor = "purple";
        viewportHeightEl.style.backgroundColor = "purple";

    } else if(window.innerWidth < 1200) {
        viewportWidthEl.style.backgroundColor = "blue";
        viewportHeightEl.style.backgroundColor = "blue";

    } else if(window.innerWidth < 1500) {
        viewportWidthEl.style.backgroundColor = "green";
        viewportHeightEl.style.backgroundColor = "green";

    } else if(window.innerWidth < 1600) {
        viewportWidthEl.style.backgroundColor = "orange";
        viewportHeightEl.style.backgroundColor = "orange";
    }

    else {
        viewportWidthEl.style.backgroundColor = "black";
        viewportHeightEl.style.backgroundColor = "black";
    }
}

viewportCalc();

window.addEventListener("resize", function(){
    viewportCalc();
});
*/

window.addEventListener('load', () => {  
    var body = document.body;
    var header = document.querySelector('.header');
    var test = document.querySelector('.container--fixed');
    var scrollY = window.pageYOffset;

    //console.log(scrollY);
    /*
    function is_touch_enabled() {
        return ( 'ontouchstart' in window ) ||
               ( navigator.maxTouchPoints > 0 ) ||
               ( navigator.msMaxTouchPoints > 0 );
    }
    */

    
    // SCROLLBAR CALCUL
    function scrollbarCalc() {
        var scrollerBox = document.querySelector('.scroller');
        var scrollbarWidth = header.offsetWidth - scrollerBox.clientWidth;
        //header.style.right = (scrollbarWidth / 2) + "px";
            
        //console.log(scrollbarWidth);

        test.style.width = scrollerBox.offsetWidth - scrollbarWidth + "px";
    } 

    //scrollbarCalc();
    

    onresize = (event) => {
        //scrollbarCalc();

        mm.add("(max-width: 991px)", () => {
            var allBigmenuResponsiveShow = document.querySelectorAll("#block-soloplafond-main-menu .nav .big-menu-open");

            allBigmenuResponsiveShow.forEach(function(el) {
                var el = el;
                el.classList.remove("big-menu-open");
                body.classList.remove("body-fixed");
            });
        });
    };
    

    // RESPONSIVE MENU
    var menuBtn = document.getElementById("menu-btn");
    //var menuBox = document.querySelector('.responsive-nav');
    //var menuBoxLink = menuBox.querySelectorAll('.nav li');
    //var menuBoxSecondLink = menuBox.querySelectorAll('.about-nav p, .about-nav li');
    //var menuBoxFooter = menuBox.querySelector('.nav-footer'); 

    function toggleMainNav() {
        body.classList.toggle("menu-open");
    }

    /*
    function toggleBodyFixed() { 
        body.classList.add("body-fixed");
        menuBtn.style.pointerEvents = "auto";
        //menuBtn.style.marginRight = scrollBarWidth + "px";

        if (body.classList.contains('body-fixed')) { 
            console.log('closed');

            window.scrollTo({
				top: scrollY,
				behavior: 'auto' 
			})
        } else {
            console.log('open');
            scrollY = window.pageYOffset;
        }
    }
    */

    function addBodyFixed() {
        body.classList.add("body-fixed");
        //console.log('titi');
    }

    function timeout_init() {
        setTimeout(addBodyFixed, 250);
    }


    /* RESPONSIVE NAV ANIM */ 
    let mainMenuResponsiveTimeline = gsap.timeline({
        //onStart: () => menuOpenUpdate(),
        //onReverseComplete: () => menuCloseUpdate(),
    });

    let bigMenuResponsiveServicesTimeline = gsap.timeline({
        //onStart: () => menuOpenUpdate(),
        //onReverseComplete: () => menuCloseUpdate(),
    });

    let bigMenuResponsiveRealisationsTimeline = gsap.timeline({
        //onStart: () => menuOpenUpdate(),
        //onReverseComplete: () => menuCloseUpdate(),
    });

    mm.add("(max-width: 991px)", () => {  
        var mainLinks = document.querySelectorAll('#menu .nav-level-0 > li.first-link > .nav-link, .responsive-showroom, .responsive-contact');

        mainMenuResponsiveTimeline.fromTo(mainLinks, {
            opacity:0,
            x: 20
        },
        {
            duration: 0.3,
            opacity: 1,
            x: 0,
            stagger: .075,
        });
        mainMenuResponsiveTimeline.pause();
    });

    menuBtn.onclick = function() {
        toggleMainNav();
        //menuBtn.style.pointerEvents = "none";

        mm.add("(max-width: 991px)", () => {
            if (body.classList.contains('menu-open')) {
                mainMenuResponsiveTimeline.play();
            } else {
                mainMenuResponsiveTimeline.reverse();

                var allBigmenuResponsiveShow = document.querySelectorAll("#block-soloplafond-main-menu .nav .big-menu-open");

                allBigmenuResponsiveShow.forEach(function(el) {
                    var el = el;
                    el.classList.remove("big-menu-open");
                });
            }
        });

        if (body.classList.contains('body-fixed')) {
            body.classList.remove("body-fixed");
            menuBtn.style.pointerEvents = "auto";
            //menuBtn.style.marginRight = "0px";
        } else {
            //timeout_init();
            //console.log('fuck-off');
        }
    };




    // RESPONSIVE NAV APPEAR  

    // BIG MENU APPEAR  
    let bigmenuTimeline = gsap.timeline({});

    let servicesTimeline = gsap.timeline({});

    let realisationsTimeline = gsap.timeline({});

    var servicesLinks = document.querySelectorAll('.mainlink-services .nav-level-1 li');
    var realisationsLinks = document.querySelectorAll('.mainlink-realisations .nav-level-1 li');

    mm.add("(min-width: 992px)", () => {
        servicesTimeline.fromTo(servicesLinks, {
            opacity:0,
        },
        {
            duration: 0.3,
            opacity: 1,
            stagger: .075,
        }, "+=0.3");
        servicesTimeline.pause();

        realisationsTimeline.fromTo(realisationsLinks, {
            opacity:0,
        },
        {
            duration: 0.3,
            opacity: 1,
            stagger: .075,
        }, "+=0.3");
        realisationsTimeline.pause();
    });  

    mm.add("(max-width: 991px)", () => {
        servicesTimeline.fromTo(servicesLinks, {
            opacity:0,
            x: 20
        },
        {
            duration: 0.3,
            opacity: 1,
            x: 0,
            stagger: .075,
        });
        servicesTimeline.pause();

        realisationsTimeline.fromTo(realisationsLinks, {
            opacity:0,
            x: 20
        },
        {
            duration: 0.3,
            opacity: 1,
            x: 0,
            stagger: .075,
        });
        realisationsTimeline.pause();
    });



    // SERVICE / REALISATION BIG MENU OPEN
    
    var allLink = document.querySelectorAll("#block-soloplafond-main-menu .nav button:not(.uptitle)");
    var allBackBtn = document.querySelectorAll("#block-soloplafond-main-menu .nav-header");

    allBackBtn.forEach(function(item) {
        item.addEventListener('click', function(){
            var clickedBack = item;
            var clickedBackParent = item.parentNode.parentNode;
            var clickedBackGrandParent = item.parentNode.parentNode.parentNode;
            //console.log(clickedBackParent);
            clickedBackParent.classList.remove('big-menu-open');
            var allBigmenu = document.querySelectorAll("#block-soloplafond-main-menu .nav .multi-menu");

            realisationsTimeline.reverse();
            servicesTimeline.reverse();
            
            mm.add("(min-width: 992px)", () => {
                body.classList.remove("body-fixed");
                clickedBackGrandParent.querySelector(".nav-link").classList.remove("button-clicked");

                //console.log(clickedBackGrandParent);

                allBigmenu.forEach(a => {
                    a.style.transition = "opacity .2s";
                });
                
                window.scrollTo({
                    top: scrollY,
                    behavior: 'auto' 
                })
            });
        });
    });

    allLink.forEach(function(item) {
        item.addEventListener('click', function(){
            var clickedButton = item;
            var clickedBigmenu = clickedButton.nextElementSibling;
            //var clickedBigmenuClose = clickedBigmenu.querySelector(".nav-header .desktop");
            var allBigmenu = document.querySelectorAll("#block-soloplafond-main-menu .nav .multi-menu");

            if (clickedButton.classList.contains('button-clicked')) {
                body.classList.remove("body-fixed");
 
                //console.log('closed - ' + scrollY);
                mm.add("(min-width: 992px)", () => {
                    allBigmenu.forEach(a => {
                        a.style.transition = "opacity .2s";
                    });
             

                    window.scrollTo({
                        top: scrollY,
                        behavior: 'auto' 
                    })
                });
            } else {
                //timeout_init();
                //body.classList.add("body-fixed");
                mm.add("(min-width: 992px)", () => {
                    if (body.classList.contains('body-fixed')) { 
                        //console.log('allReady');
                        
                        allBigmenu.forEach(a => {
                            a.style.transition = "opacity 0s";
                        });
                    
                    } else {
                        scrollY = window.pageYOffset;
                    }
                });

                timeout_init();
                //console.log('open - ' + scrollY);
            }

            var allClickedButton = document.querySelectorAll("#block-soloplafond-main-menu .nav .button-clicked");
            var allBigmenuShow = document.querySelectorAll("#block-soloplafond-main-menu .nav .big-menu-open");

            clickedBigmenu.classList.toggle("big-menu-open");
            clickedButton.classList.toggle("button-clicked");

            mm.add("(min-width: 992px)", () => {
                bigmenuTimeline.play();
            });

            realisationsTimeline.reverse(-1);
            servicesTimeline.reverse(-1);
            
            if (clickedButton.parentNode.classList.contains('mainlink-services')) {
                servicesTimeline.play();
            } else {
                realisationsTimeline.play();
            }
            
            allBigmenuShow.forEach(function(el) {
                var el = el;
                el.classList.remove("big-menu-open");
            });

            allClickedButton.forEach(function(el) {
                var el = el;
                el.classList.remove("button-clicked");
            });
        });
    });
  
    // UPDATE MAIN NAV ACTIF
    function getSecondPart(str) {
        return str.split('mainlink-')[1];
    }    

    var allExpandedLinks = document.querySelectorAll("#block-soloplafond-main-menu .nav .nav-item-expanded");

    allExpandedLinks.forEach(function(item) {
        var expandedLink = item;
        var expandedLinkClass = item.getAttribute('class');
        var expandedLinkMainClass = getSecondPart(expandedLinkClass);
        
        //console.log(buttonItemClassMainlink);

        if (window.location.href.indexOf(expandedLinkMainClass) > -1) {
            expandedLink.classList.add('nav-item-active-trail');
        }
    });
    
    // HOVER BIG MENU ELEMENT
    var allBigmenu = document.querySelectorAll(".nav-level-1, #block-services-cta .nav-level-0");

    mm.add("(min-width: 992px)", () => {
        allBigmenu.forEach(function(item) {
            var bigmenuLinks = item.querySelectorAll(".nav-item a");

            bigmenuLinks.forEach(function(item) {
                item.addEventListener('mouseover', function(){
                    bigmenuLinks.forEach(function(item) {
                        item.style.opacity = "0.3";
                    });

                    if (!item.parentNode.classList.contains('nav-item-active-trail')) {
                        item.style.opacity = "1";
                    }
                });

                item.addEventListener('mouseout', function(){
                    //console.log('what');

                    bigmenuLinks.forEach(function(item) {
                        item.style.opacity = "1";

                        if (item.parentNode.classList.contains('nav-item-active-trail')) {
                            item.style.opacity = "0.5";
                        }
                    });

                    //item.style.opacity = "1";
                });
            });    
        });
    });


    // REALISATION SWIPER 
    const swiper = new Swiper('.swiper-container', {
        slidesPerView: "auto",    
        spaceBetween: 40,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: "progressbar",

        breakpoints: {
            // when window width is >= 1000px
            1000: {
                slidesPerView: "auto",        
                spaceBetween: 40,
            },
          }
        },  
    });

    // HEADER ANIMATION SCROLL
    let headerJS =  document.getElementById('header-js');
    var handlerFired;
    var scrollerBox = document.querySelector('.scroller');

    
    mm.add("(max-width: 991px)", () => {
        /*
        scrollerBox.addEventListener('scroll', function(e){
            var containerTop = document.querySelector('.jumbotron').getBoundingClientRect().top;

            //console.log(containerTop);

            if (containerTop <= -100) {
                if (!handlerFired) {
                    handlerFired = 1;
                    //console.log('container at top of viewport or above');
                    headerJS.classList.add('header--tiny');
                }    
            }

            if (containerTop > -100) {
                handlerFired = 0;
                
                if (headerJS.classList.contains('header--tiny')) { 
                    //console.log('container at bottom of viewport or below');
                    headerJS.classList.remove('header--tiny');
                }
            }
        });
        */
    });
    

    mm.add("(max-width: 991px)", () => {
        
        ScrollTrigger.create({
            start: "top -100px",
            scroller: scrollerBox,
            end: 99999,
            //markers: true,

            toggleClass: { targets: 'header', className: 'header--tiny' },
        });
        

       
    });
    
    mm.add("(min-width: 992px)", () => {
        ScrollTrigger.create({
            trigger: '.header',
            start: () => 'top+=100px top',
            end: () => 'top+=100px top',
            //markers: true,

            onEnter: function() {
                //console.log('yes,wein');
                headerJS.classList.add("header--tiny");
            },
            onEnterBack: function() {
                //console.log('no,weout');
                headerJS.classList.remove("header--tiny");
            },
        }) 
    });
    











    // CONTACT FORM
    /// Add focus on select option:selected
    function focusField() {
        if($(this).find('option:selected').val() != '') {
            $(this).addClass('focus');
        } else {
            $(this).removeClass('focus');
        }

        if($(this).parentsUntil('.container-inline').find('select option:selected').val() == '') {
            //console.log('not-good');
        }
    }

    $('form select').each(function (e) {
        $(this).on("keydown keyup change",focusField);
    });
});